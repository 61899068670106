import Axios from "axios";
import { getLang } from "../state/reducers/langSession";

export const middlewareInstance = Axios.create({
  baseURL: process.env.GATSBY_NEW_API_URL,
});

middlewareInstance.interceptors.request.use(async (config) => {
  config.headers.LOCALE = getLang();
  return config;
});
